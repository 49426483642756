<template>
  <!-- Footer Section -->
  <footer id="footer-section">
    <p class="copyright">
      &copy; <a href="https://nu.edu">National University</a> 2021, All Rights Reserved.
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
